
/* navigation */

.middle-content {
    margin-top: 5vh;
  }

.submit {
    border-color: rgb(80, 79, 80);
    background: #1c2c4c;
    color:white;
    border: 1px solid;
    text-align: center;
    width:45%;
    border-radius:9px;
    margin:auto;
}
.header {
    justify-content: space-between;
    background-color: #111111;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: top .2s ease-out;
    width: 100%;
    z-index: 100001;
    display:block;
}

.header .header-wrap {
    display: flex;
}

.padded-container {
    padding-left:40px;
    padding-right:40px;
}

.header .header-nav {
    align-items: center;
    display: flex;
    flex: 1;
    height: 50px;
}

.header .header-nav a.site-logo {
    float: left;
    margin-right: 45px;
    position: relative;
    transition: opacity .6s ease-in-out;
}

.site-logo {
    display: inline-block;
    height: 45px;
    margin-top: 5px;
}

.header .header-nav ul {
    align-items: center;
    display: flex;
    float: right;
    position: relative;
    margin-top:30px;
}

.header .header-nav--items > li {
    display: block;
    margin-right: 34px;
}

.header .header-nav--items li .header-topLevelItem, .header .header-nav--items li a {
    color: #fff;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .8px;
    line-height: 18px;
    opacity: 1;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    
}

.header .header-account {
    align-items: center;
    display: flex;
    height: 56px;
    transition: opacity .6s ease-in-out;
    z-index: 100010;
    margin-top:18px;
}

.header .header-nav--items>li {
    display: block;
    margin-right: 24px;
}

.content-margin {
    margin-top: 20vh;
}

.signup {
    border-radius: 7px;
    padding:7px;
}

.signup-box {
    width: 400px;
    margin: auto;
}

.info-container {
    display: flex;
    justify-content: space-between;
}

.watch-button-container {
    margin-top:25px; 
    text-align: right;
}

.watch-button {
    background-color:#0030ff;
    color:white;
    padding:5px;
    border-radius:5px;
    margin-right:25px;
}

.artist-info-box {
    margin-right:20%;
}

.homepage-img {
    height: 400px;
}


/* middle content */

.thumbnails {
    width: 289px;
}

.icons {
    width: 30px;
    height: 30px;
    border-radius: 18px;
}

.icons-name {
   position: relative;
   margin-left: 10px;
   color:#fff;
}

/* live video and chat styles */

#video-box {
    width:1040px;
    height:720px;
    margin-top:-65px;
}

.live-video {
    width:1080px;
    height:720px;
    margin-top:-75px;
  }
  
  .live-content-margin {
    margin-top:14vh;
    display:flex;
    justify-content: space-around;
    margin-right:-5%;
    margin-left:-5%;
  }
  
  
  .messages-wrapper {
      padding: 30px;
      margin-bottom: 60px;
      height:675px;
      overflow-y:hidden;
    }
    .chat-bubble {
      color: #1c2c4c;
      width: 275px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }

    .chat-bubble.right {
        /* background: rgba(211,211,211, 0.1);
        box-shadow: -2px 2px 1px 1px rgb(56, 56, 56); */
        border:1px solid #333333;
        padding:8px;
        border-radius: 8px;
      }
      .chat-bubble__left {
        /* width: 35px;
        height: 35px;
        border-radius: 50%; */
        border:1px solid #333333;
        padding:8px;
        border-radius: 8px;
      }

    .user-name {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 0.9rem;
        color: #fff;
      }
      .user-message {
        word-break: break-all;
      }
      .message-time {
        display: block;
        text-align: right;
      }
      
      /* send message */
      .send-message {
        position: fixed;
        bottom: 0px;
        width: 20%;
        padding: 20px 30px;
        background-color: #1c1d1f;
        display: flex;
      }
      .send-message > input {
        height: 40px;
        padding: 10px 10px;
        border-radius: 5px 0 0 5px;
        border: none;
        flex-grow: 1;
        background-color: white;
        color: #1c2c4c;
        font-size: 1rem;
      }
      .send-message > input:placeholder {
        color: #ddd;
      }
      .send-message > :is(input, button):focus {
        outline: none;
        border-bottom: 1px solid #181818;
      }
      .send-message > button {
        width: 70px;
        height: 40px;
        padding: 5px 15px;
        border-radius: 0 5px 5px 0;
        color: #ffffff;
        border: 1px solid #353535;
        background-color:transparent;
        font-weight: 600;
      }
    
      .awesome-icons {
        text-align: right;
        width:40px;
        color:#fff;
        margin-top:3px;
      }


/* playlist */

.ad-area {
    display: flex;
    justify-content: space-around;
    padding:16px;
    background-color: #222222;
    margin-bottom: 15px;
}


.links {
    color:#0030ff;
}

.top-border {
    margin-top:80px;
    border-top: 1px solid #222222;
    padding-top: 25px;
}

.membership-gen {
    background-color:#222222;
    padding:15px;
}
.membership-pro {
    background-color:#333333;
    padding:15px;
}

.color-red {
    color:red;
}

.more-membership {
    padding: 25px;
}

.live-video {
    width:1280px;
    height:720px;
}

#video-box {
    width:1280px;
    height:720px;
}

.extra-top {
    padding-top: 5vh;
}


/* pop up styles */

.tip-modal-big {
    background-color: #222;
    padding: 0px 50px 50px 50px;
    border-radius: 7px;
    width: 55%;
    margin:auto;
}

.tip-modal-big .close {
    color:white;
    font-size: 30px;
    font-weight: bold;
}

.qrcodeimage {
    width:200px;
}

.star {
    color:#00d2ff;
    font-size:75px;
}

.homepage-img {
    height: 400px;
}




/* responsive styles */

@media (max-width: 1368px) {

    .homepage-img {
       
        height: 310px;
    }

    .home-image {
        text-align: center;
        padding-top:2vh;
    }

    .wordwrap {
        padding:25px;
        margin-left: 50px;
    }

    p {
    font-size: 16px;
    }

    h2 {
        font-size:18px;
    }

    .homepage-content {
        margin-left:-100px;
        margin-top:3vh;
        position:relative;
    }

    .middle-content {
        margin-top: 8vh;
      }

    .header .header-nav--items li .header-topLevelItem, .header .header-nav--items li a {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: .8px;
        line-height: 18px;        
    }

    .artist-info {
        font-size:12px;
    }

    .qrcodeimage {
        width:200px;
    }

    .thumbnails {
        height: 150px;
        width: 215px;
    }

    .info-container {
        display: flex;
        justify-content: space-between;
    }

    .watch-button-container {
        margin-top:25px; 
        text-align: right;
    }

    .watch-button {
        background-color:#0030ff;
        color:white;
        padding:7px;
        border-radius:8px;
    }

    #video-box {
      width:720px;
      height:480px;
      margin-top:10px;
      padding:0;
  }

    .live-content-margin {
      margin-top: 18vh;
      margin-left:-75px;
  }
 
}

/* responsive styles */

@media (max-width: 1024px) {

    .homepage-img {
        height: 240px;
    }

    p {
    font-size: 12px;
    }

    h2 {
        font-size:18px;
    }

    .homepage-content {
        margin-left:0px;
        margin-top:1vh;
        position:relative;
    }

    .middle-content {
        margin-top: 8vh;
      }


    .header .header-nav ul {
        align-items: center;
        display: flex;
        float: right;
        position: relative;
        margin-top:40px;
    }
    
 
    .header .header-nav--items li .header-topLevelItem, .header .header-nav--items li a {
        color: #fff;
        font-family: Proxima Nova Regular,sans-serif;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: .8px;
        line-height: 18px;
        opacity: 1;
        position: relative;
        text-transform: uppercase;
        white-space: nowrap;
        
    }
    
    .header .header-account {
        align-items: center;
        display: flex;
        height: 56px;
        transition: opacity .6s ease-in-out;
        z-index: 100010;
        margin-top:18px;
    }
    
    .header .header-nav--items>li {
        display: block;
        margin-right: 10px;
    }
    

    .artist-info-box {
        margin-right:10px;
    }

    .artist-info {
        font-size:12px;
    }

    .thumbnails {
        height: 150px;
        width: 215px;
    }

    .info-container {
        display: flex;
        justify-content: space-between;
    }

    .watch-button-container {
        margin-top:25px; 
        text-align: right;
    }

    .watch-button {
        background-color:#0030ff;
        color:white;
        padding:3px;
        border-radius:8px;
    }

    #video-box {
      width:720px;
      height:480px;
      margin:0;
      padding:0;
  }

    .live-content-margin {
      margin-top: 18vh;
      margin-left:-75px;
  }
 
}


@media (max-width: 640px) {
    body {
    font-size: 4vw;
    }

    .middle-content {
        margin-top: 6vh;
      }

 .mobile-video-container {
    margin-top:55px;
    display:flex;
    justify-content: center;
 }

    .send-message {
        position: fixed;
        bottom: 0px;
        width: 20%;
        padding: 0 0;
        background-color: #1c1d1f;
        display: flex;
      }

    #video-box {
        width:360px;
        height:240px;
        margin-top:-25px;
    }

      
  .messages-wrapper {
    padding: 30px;
    margin-bottom: 60px;
    height:475px;
    overflow-y:hidden;
  }

    .send-message {
        bottom:1vh;
        margin-left:18%;
        padding: 0px 0px;
    }

  }

