

.mobile-topnav {
    margin-top:10px;
}

.mobile-button {
    border-radius: 7px;
    padding:15px;
    background-color:#0030ff;
    color:white;
    margin:25px;
}

.mobile-live-video {
  width:640px;
  height:360px;
}

.signup-box {
    width:300px;
    margin: auto;
}

/* .membership-gen {
    background-color:#222222;
    padding:5px;
} */


  
  /* pop up styles */

.tip-modal {
  background-color: #222;
  padding: 0px 15px 15px 15px;
  border-radius: 7px;
  width: 85%;
  margin:auto;
}

.tip-modal .close {
  color:white;
  font-size: 30px;
  font-weight: bold;
}



