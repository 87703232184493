
.row-container {
    border: 2px solid #222;
    padding:15px;
}

/* progress bar */

.rap-pp-button {
    display: none;
}

.custom-style.rap-container {
	background-color: #111111;
	background-image: linear-gradient(147deg, #111111 0%, #002fffc7 74%);
	color: rgb(255, 255, 255);
}

.custom-style.rap-container .rap-pp-icon path,
.custom-style.rap-container .rap-volume-btn path {
	fill: white;
}

.custom-style.rap-container .rap-slider .rap-progress {
	background-color: #daecff;
}

.custom-style.rap-container .rap-volume .rap-volume-controls {
	background-color: #111111;
	background-image: linear-gradient(147deg, #111111 0%, #002fffc7 74%);
}

.custom-style.rap-container .rap-slider .rap-progress .rap-pin {
	background-color: #c3d5ff;
	box-shadow: 0 0 9px 7px #333;
}

.custom-style.rap-container svg.rap-pp-icon:hover,
.custom-style.rap-container .rap-volume-btn svg:hover {
	filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.9));
}




/* .layout {
    width:100%;
}

.track-history {
      border-bottom:1px solid #0000ff;
      margin-bottom:15px;
  }

  .biopicsjon {
    border:5px solid #fff;
    transform: rotate(-5deg);
    -webkit-box-shadow: 5px 7px 13px 5px rgba(0,0,0,0.53); 
box-shadow: 5px 7px 13px 5px rgba(0,0,0,0.53);
width:59%;
  }


  .player {
    width:100%!important;
}

.switch {
    display:none;
}


.social-buttons {
    width:319px;
    margin-bottom:7px;
    margin-top:7px;
}

.buttons-right {
    float:right;
}

.radioking {
    border-radius: 5px;
    width: 100%;
    height:145px;
    min-width: 470px;
    min-height: 0;
}

.marginbottom {
    margin-bottom:7px;
} */