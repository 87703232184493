body {
  background-color:#111111!important;
  color:#ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom Scrollbar */
::-webkit-scrollbar{width:0.5rem}
::-webkit-scrollbar-track{background:#222937; border-radius: 50px;}
::-webkit-scrollbar-thumb{background:#0030ff; border-radius: 50px;}

h2 {
  font-size: 26px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color:#00d1ff;
}

.btn-success {
  background-color: #0030ff;
  border:#111111;
}

